@font-face {
    font-family: 'GloriaHallelujah';
    src: url('./GloriaHallelujah-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'PoppinsBold';
    src: url('./Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Italic.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'PoppinsMedium';
    src: url('./Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('./Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Black.ttf') format('truetype');
    font-weight: 900;
}